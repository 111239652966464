<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Seat Arrangement</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12" class="mb-3">
        <vs-input
          class="w-full"
          label="Seat Number (*)"
          v-model="payload.name"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("name")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module
import seatStore from "@/store/seat";

export default {
  metaInfo: {
    title: "Add Seat Arrangement",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  data: () => ({
    payload: {
      name: "",
    },
  }),

  methods: {
    async storeData() {
      const formData = new FormData();
      formData.set("name", this.payload.name);
      try {
        const response = await this.$store.dispatch("seat/storeSeat", formData);
        if (!response.success) {
          throw error(response.message);
        }
        this.$vs.notify({
          title: "Successful",
          text: response.msg_status,
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
        });

        this.$router.push("/seat-arrangement");
      } catch (error) {
        console.log(error);
        this.$catchErrorResponse(error);
      }
    },
  },

  created() {
    if (!seatStore.isRegistered) {
      this.$store.registerModule("seat", seatStore);
      seatStore.isRegistered = true;
    }
  },
};
</script>

<style></style>
